body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  
  background-color: #dedede;
}

.carousel-item img {
  margin-left: auto;
  margin-right: auto;
  object-fit: fill;
  max-width: 1000px ;
}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}

/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}

.marketing .clickable {
  cursor: pointer;
}

/* rtl:end:ignore */

@media (min-width: 40em) {

  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

/* .carousel .carousel-inner{
  height:700px;
  }
  .carousel-inner .carousel-item img{
  min-height:200px;

  object-fit:cover
  }*/

@media(max-width:72em) {
  .carousel-item {
    height: auto;
  }
  .carousel-item img {
    width: 100%;
  }
}

.map {
  width: 100%;
  height: 20rem;
}

@media(min-width: 3000px) {
  .map {
    min-height: 40em;
  }
}

@font-face {
  font-family: 'CustomFont';
  src: url("Papyrus.ttf");
}

.main-title {
  font-family: 'CustomFont';
  font-size: xx-large;
}

@media(max-width:280px) {
  .main-title {
    font-family: 'CustomFont';
    font-size: large;
  }
}